.call {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #094ca3;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: ping 1.5s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.whatsapp {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 120px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: ping 1.5s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.call-icon, .whatsapp-icon {
  align-self: center !important;
}



@keyframes ping {
  75%, 100% {
    transform: scale(1.1);
  }
}

